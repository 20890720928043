<template>
  <div class="view view--login">
    <div class="view-body">
      <Section>
        <Container>
          <div class="login-block">
            <router-link :to="{ name: 'home' }"
              class="navbar-logo">
              <img class="login-logo"
                alt="Le logo de OSS"
                src="../../assets/logo-oss-full.svg" />
            </router-link>

            <h1>Créez un nouveau mot de passe</h1>

            <template v-if="!displayError">
              <p>
                Entrez votre mot de passe une première fois, puis une seconde fois
                par mesure de sécurité. Validez ensuite votre choix et votre mot
                de passe sera automatiquement modifié.
              </p>

              <div class="form form--login">
                <form @submit.prevent="onSubmit()">
                  <Input id="motdepasse"
                    textInfo="Minimum 8 caractères"
                    label="Indiquez votre mot de passe"
                    :type="inputType" />

                  <Input id="motdepasse_confirmation"
                    label="Répétez votre mot de passe"
                    :type="inputType" />

                  <Checkbox v-model="displayPassword"
                    inline
                    :items="[{ value: 'true' }]"
                    label="Afficher le mot de passe" />

                  <Btn btnType="submit"
                    text="Générer un nouveau mot de passe"
                    color="primary"
                    block
                    icon="key" />
                </form>
              </div>
              <p>
                <router-link :to="{ name: 'usernameForgotten' }">Identifiant oublié&nbsp;?</router-link>
              </p>
              <p>
                <router-link :to="{ name: 'login' }">Se connecter</router-link>
              </p>
            </template>

            <template v-if="displayError">
              <p class="color-error">
                <b>
                  Le lien de renouvellement de mot de passe à expiré.
                </b>
              </p>
              <p>
                <Btn :to="{ name: 'passwordForgottenStep1' }"
                  text="Renouveler ma demande"
                  color="primary" />
              </p>
            </template>

          </div>
        </Container>
      </Section>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/form/Checkbox.vue'
import Input from '@/components/form/Input.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import * as yup from 'yup'

export default {
  name: 'PasswordForgottenStep2View',

  components: {
    Input,
    Btn,
    Section,
    Container,
    Checkbox,
  },

  data() {
    // Define a validation schema
    const validationSchema = yup.object().shape({
      motdepasse: yup.string().min(8).required(),
      motdepasse_confirmation: yup
        .string().min(8)
        .oneOf([yup.ref('motdepasse'), null], 'Les mots de passe doivent correspondre'),
    })

    const { isSubmitting, errors } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      this.sendRequest(values)
    })

    return {
      token: '',
      onSubmit,
      errors,
      isSubmitting,
      displayError: false,
      inputType: 'password',
      displayPassword: false,
    }
  },
  watch: {
    displayPassword() {
      this.inputType = (this.displayPassword) ? 'text' : 'password'
    },
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },
  created() {
    this.token = this.$route.params.token
  },
  methods: {
    sendRequest(values) {
      return this.fetchService
        .post(`authentification/mdp/${this.token}`, values, false)
        .then(
          (response) => {
            this.formService.resetForm()
            this.emitter.emit('alert', {
              type: 'success',
              content: response.data,
            })
            this.$router.push({ name: 'login' })
          },
          (responseError) => {
            if (
              responseError?.data?.error
              && responseError?.data?.error === 'Votre demande à expirée.'
            ) {
              this.displayError = true
            }
            this.formService.handleApiError(responseError)
          },
        )
    },
  },
}
</script>
